.App {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    gap: 0px 0px;
}

.App>footer {
    padding: 1em;
    font-size: small;
}

.toolbar {
    margin: 1em;
    display: flex;
    gap: .5em;
    /* background-color: #c7c8ca; */
}

/* .toolbar button {
    width: 6em;
    border-radius: 0;
    border: none;
    background: none;
} */