.Editor {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.Editor .toolbar {
    margin: 1em 0 0 1em ;
}

.Editor .codeErrorMessageContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.Editor .codeErrorMessageContainer .errorMessage {
    flex: 1;
    margin: 1em;
    padding: 1em;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    align-self: flex-start;
}

.Editor .codeErrorMessageContainer .code {
    resize: none;
    flex: 1;
    margin: 1em;
}

.Editor #ace-editor {
    height: 100%;
}