.Running {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: "toolbar toolbar" "codeblock variables" "codeblock console";
}

.Running .CodeBlock {
    margin: 1em;
    grid-area: codeblock;
}

.Running .toolbar {
    grid-area: toolbar;
}

.Running .Console, .Running .Variables {
    font-family: 'Courier New', Courier, monospace;
}

.Running .Variables {
    grid-area: variables;
}

.Running .Console {
    grid-area: console;
}