.CodeBlock {
    font-family: 'Courier New', Courier, monospace;
}

.CodeBlock span {
    display: inline-block;
}

.CodeBlock div {
    display: table;
}

.CodeBlock .indentedBlock {
    margin-left: 1.25em;
     border: 0.10em solid black;
     border-radius: .56em;
     padding: 0.3333em;
     background-color: #ffffff;
}

.CodeBlock .rounded {
    border: 0.10em solid black;
    border-radius: .56em;
    padding: 0.3333em;
}

.CodeBlock .oval {
    border: 0.10em solid black;
    border-radius: 500px;
    padding: 0.25em;
    /* margin: 0.3333em 0; */
}


.CodeBlock .filled {
    border: 0.10em solid black;
    border-radius: .56em;
    padding: 0.3333em;
    background-color: #c7c8ca;
}

.CodeBlock .box {
    border: 0.10em solid black;
    padding: 0.25em;
}

.condition {
    margin-bottom: .3em;
}

.CodeBlock .alt {
    display: none;
}

.CodeBlock .running {
    position: relative;
}

.CodeBlock .runningArrow {
    font-family: 'Courier New', Courier, monospace;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(-.2em);
}

.CodeBlock .errorContainer {
    position: relative;
}

.CodeBlock .errorText {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 100%;
    pointer-events: none;
    background-color: rgb(248, 215, 218, 75%);
    border: 1px solid #f5c6cb;
    color: #721c24;
    padding: .25em .5em;
    z-index: 10;
    min-width: 8em;
    transform: translateX(.5em) translateY(-50%);
}

.CodeBlock .errorText::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #721c24 transparent transparent;
}

.CodeBlock .errorElement {
    position: static;
}